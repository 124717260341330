.table-light {
  th { min-width: 45px;}
  th:last-child { min-width: 85px; }

  th.bulk-checkbox-cell { min-width: 20px; }
}

.table-wrapper { overflow-x: auto; }

.overflow-x { overflow-x: auto }

.project-data-list li { flex: 0 0 50%; }

@media only screen and (max-width: 992px) {
  .project-data-list, .profile-data-list { overflow-x: auto}
  .project-data-list li, .profile-data-list li { flex: 0 0 100%; }
  .flex-2-col { flex-wrap: wrap; }
  .edit-button-wrapper { margin-left: 0 !important; margin-top: 20px }
  .mb-20px { margin-bottom: 20px !important }
  .flex-2-col .no-margin,
  .no-margin {
    margin: 0 !important;
  }
  .margin-top {
    margin-top: 10px;
  }
}

