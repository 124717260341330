//
// _email.scss
//

.simplebar-content {
  .mail-list {
    a {
      color: $menu-text-color;

      &.active {
        color: $menu-text-color;

        i {
          color: $menu-text-color;
        }
      }

      &:hover {
        color: $secondary-color;
      }
    }
  }
}