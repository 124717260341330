/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/
.flatpickr-calendar {
    background-color: $dropdown-bg !important;
    box-shadow: 1px 0 0 $border-color, -1px 0 0 $border-color, 0 1px 0 $border-color, 0 -1px 0 $border-color, 0 3px 13px rgba(0, 0, 0, 0.08) !important;

    &.hasTime .flatpickr-time {
        height: 40px;
        border-top: 1px solid $border-color !important;
    }
}

.flatpickr-day {
    color: var(--vz-body-color);

    &.today {
        border-color: $primary !important;
        background-color: rgba($primary, 0.1) !important;
        box-shadow: $element-shadow;

        &:hover,
        &:focus {
            border-color: $primary !important;
            background-color: rgba($primary, 0.15) !important;
            color: var(--#{$variable-prefix}dark) !important;
        }
    }

    &:hover,
    &:focus {
        background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.7);
    }

    &.inRange,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.today.inRange,
    &.prevMonthDay.today.inRange,
    &.nextMonthDay.today.inRange,
    &:hover,
    &.prevMonthDay:hover,
    &.nextMonthDay:hover,
    &:focus,
    &.prevMonthDay:focus,
    &.nextMonthDay:focus {
        background-color: var(--#{$variable-prefix}light);
        border-color: var(--#{$variable-prefix}light);
    }
}

.flatpickr-time {
    input {
        color: var(--#{$variable-prefix}body-color) !important;
    }

    .flatpickr-time-separator,
    .flatpickr-am-pm {
        color: var(--#{$variable-prefix}body-color) !important;
    }

    input,
    .flatpickr-am-pm {

        &:hover,
        &:focus {
            background: rgba($primary, 0.04) !important;
        }
    }
}

.flatpickr-months {

    .flatpickr-prev-month,
    .flatpickr-next-month {
        color: rgba($white, 0.9) !important;
        fill: rgba($white, 0.9) !important;

        &:hover {
            color: #959ea9 !important;

            svg {
                fill: rgba($white, 0.9) !important;
            }
        }

        svg {
            width: 14px;
            height: 14px;

            path {
                transition: fill 0.1s;
                fill: inherit;
            }
        }
    }
}

.flatpickr-current-month {
    .flatpickr-monthDropdown-months {
        font-size: $font-size-base !important;
    }

    input {
        &.cur-year {
            font-size: $font-size-base !important;
            font-weight: $font-weight-semibold !important;
        }
    }
}

.multiselect {
    border-color: var(--vz-input-border) !important;
    background: $input-bg !important;
}

.multiselect-search {
    color: $input-color !important;
    background: $input-bg !important;
}

.dropzone {
    // width: 400px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
    border: 2px dashed #41b883;
    background-color: #fff;
    transition: 0.3s ease all;

    label {
        padding: 8px 12px;
        color: #fff;
        background-color: #41b883;
        transition: 0.3s ease all;
    }

    input {
        display: none;
    }
}

.active-dropzone {
    color: #fff;
    border-color: #fff;
    background-color: #41b883;

    label {
        background-color: #fff;
        color: #41b883;
    }
}

.upcoming-scheduled {
    .flatpickr-input {
        display: none;
    }
}

.upcoming-events {
    i {
        width: 14px;
        height: 14px;
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
    }

    .mdi-checkbox-blank-circle::before {
        content: "" !important;
    }
}

.multiselect-tags .multiselect-tags-search-wrapper .multiselect-tags-search {
    background-color: transparent;
    color: var(--vz-input-color);
    border: 1px solid transparent !important;

    &:focus,
    &:hover {
        outline: $border-color;
    }
}

.multiselect-dropdown {
    .multiselect-options {
        list-style: none;
        padding-left: 0;
    }

    .multiselect-no-results {
        text-align: center;
        padding-bottom: 1px;
    }

    &.is-hidden {
        display: none !important;
    }
}

.flatpickr-day.inRange {
    box-shadow: none !important;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
    background-color: var(--vz-light);
}

.swal2-popup {
    background-color: var(--vz-modal-bg) !important;
}

.slider-target {
    .slider-base {
        background-color: var(--vz-light) !important;
    }
}

// switches 

.toggle-container {
    box-shadow: none !important;
    ;
}

.toggle-red {
    --toggle-bg-on: var(--vz-red);
    --toggle-border-on: var(--vz-red);
}

.toggle-primary {
    --toggle-bg-on: var(--vz-primary);
    --toggle-border-on: var(--vz-primary);
}

.toggle-info {
    --toggle-bg-on: var(--vz-info);
    --toggle-border-on: var(--vz-info);
}

.toggle-warning {
    --toggle-bg-on: var(--vz-warning);
    --toggle-border-on: var(--vz-warning);
}