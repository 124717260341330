//
// _listjs.scss
//

.pagination-wrap {
  .listjs-pagination{
    li{
      &.active{
        .page{
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }
    }
  }
}
