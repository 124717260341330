//
// _topbar.scss
//

html[data-topbar="dark"] {
  #page-topbar,
  .topbar-user {
    background-color: $primary-color;
  }

  .btn {
    &.btn-topbar {
      color: $color-white;

      &.active,
      &:hover,
      &:focus {
        background-color: rgba($color-white, 0.07);
        color: $color-white;
      }
    }
  }

  #page-topbar {
    border-color: $primary-color;
  }

  .navbar-header {
    .btn-topbar {
      &:hover {
        background-color: #5399a5;
      }
    }
  }
}
