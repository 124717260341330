.flatpickr-calendar {
  &.animate {
    animation: unset !important;
  }

  .flatpickr-months {
    background-color: $primary-color;
  }

  .flatpickr-day {
    &.selected,
    &.startRange,
    &.endRange,
    &.selected.inRange,
    &.startRange.inRange,
    &.endRange.inRange,
    &.selected:focus,
    &.startRange:focus,
    &.endRange:focus,
    &.selected:hover,
    &.startRange:hover,
    &.endRange:hover,
    &.selected.prevMonthDay,
    &.startRange.prevMonthDay,
    &.endRange.prevMonthDay,
    &.selected.nextMonthDay,
    &.startRange.nextMonthDay,
    &.endRange.nextMonthDay {
      background-color: $secondary-color;
      border-color: $secondary-color;
      color: $color-white;
    }
  }

  .flatpickr-rContainer {
    .flatpickr-weekdays {
      background-color: $secondary-color;
    }

    .flatpickr-monthSelect-months {
      .flatpickr-monthSelect-month.selected,
      .flatpickr-monthSelect-month.startRange,
      .flatpickr-monthSelect-month.endRange {
        background-color: $secondary-color;
        border-color: $secondary-color;
      }
    }
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: $secondary-color;
    border-color: $secondary-color;
  }
}