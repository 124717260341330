@include media-breakpoint-max(md) {
  .table-wrapper {
    overflow: auto;
  }
}

.filters-button {

  &.waves-effect {
    overflow: unset;
  }

  .filters-counter {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    border-radius: 50%;
    width: $filters-counter-size;
    line-height: $filters-counter-size;
    font-size: $filters-counter-font-size;
    background-color: $woorpla-main-red;
  }
}

tr.archived {
  background-color: $archived-bg-color;
  color: $archived-color;
}

tr.blacklisted {
  background-color: $blacklisted-bg-color;
  color: $blacklisted-color;
}

.table-bordered {
  tbody {

    td {
      border-left-width: 1px;
      border-left-style: solid;
    }

    td {
      border-right-width: 1px;
      border-right-style: solid;
    }
  }
}

.table-bordered-inner {

  tbody,
  tfoot {

    td:not(:first-child):not(.border-right-2) {
      border-left-width: 1px;
      border-left-style: solid;
    }

    td:not(:last-child):not(.border-right-2) {
      border-right-width: 1px;
      border-right-style: solid;
    }
  }
}

.border-left-2 {
  border-left-width: 2px;
}

.border-right-2 {
  border-right-width: 2px;
}

.first-column-sticky {

  th:first-child {
    background-color: $table-head-bg-color;
  }

  td:first-child {
    background-color: $table-bg-color;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background-color: $table-border-color;
    }
  }
}

.sticky-table {

  .table-wrapper.overflow-auto {

    td.sticky,
    th.sticky {
      position: sticky;
      z-index: 1;
    }

    td.sticky {
      background-color: $table-bg-color;
    }
  }
}

.bulk-checkbox-cell {
  text-align: center;

  input[type="checkbox"] {
    cursor: pointer;
    vertical-align: middle;
  }
}