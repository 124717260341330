.rotation-table-container:not(.table-bordered-inner) {

  .expand-button {
    position: absolute;
    top: $rotation-table-expand-button-top;
    right: $rotation-table-expand-button-right;
  }

  .overflow-auto {

    table {

      tr {

        th.sticky.border-right-2 {
          @include custom-border-right();

          &::after {
            width: 2px;
          }
        }

        td.sticky {
          .cell-extender {
            width: $rotation-table-default-input-width;
          }

          @include custom-border-right();

          &.border-right-2::after {
            width: 2px;
          }

          .text-input-wrapper,
          .date-input-wrapper {

            input {
              padding: 4px;
            }
          }

          &.is-updating {

            .text-input-wrapper,
            .date-input-wrapper {
              position: relative;

              input {
                @include cell-loading-style();
              }

              @include input-loader-element();
            }
          }
        }

        td.sticky,
        th.sticky {
          padding: 0;
          border-left-width: 0;
          border-right-width: 0;

          .cell-extender {
            padding: 0.75rem 0.3rem;
            min-width: 90px;
          }

          // Position, Responsible PM, Comments columns
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {

            .cell-extender {
              min-width: 120px;
            }
          }

          // Vacation request
          &:nth-child(6) {
            .cell-extender {
              min-width: 160px;
            }
          }

          // Employment date and Dismiss date columns
          &:nth-child(13),
          &:nth-child(14) {

            .cell-extender {
              min-width: 92px;
            }
          }
        }

        td.month-cell {
          position: relative;
          min-width: $rotation-table-month-cell-min-width;
          padding: 3px;
          cursor: pointer;
          vertical-align: middle;

          .form-control {
            padding: 0.3rem 0.6rem;
          }

          &.is-updating {
            @include cell-loading-style();

            @include input-loader-element();
          }
        }
      }
    }

    @include custom-scrollbar-style(false);
  }
}

.rotation-select.select-input-dropdown {
  @include custom-scrollbar-style(true);

  ul {

    li {
      padding: 0.1875rem 0.25rem;
      text-align: center;
    }
  }
}