.custom-select {

  .dropdown {
    overflow: auto;

    ul {
      li.assigned {
        cursor: auto;
        background-color: $disabled-option-bg;
        color: $disabled-option-color;

        .disabled-text {
          color: $disabled-text-color;
          font-size: $disabled-text-font-size;
          line-height: $disabled-text-line-height;
        }
      }
    }
  }

  .multiple-clear {
    position: absolute;
    right: 5px;
    top: 7px;
    padding: 2px 5px;
    background: $color-light-gray;
    border-radius: 50rem;
    cursor: pointer;
    color: $color-white;
  }
}

// .dropdown class should be defined separately, because it is repositioned inside body HTML tag
.dropdown.select-input-dropdown {
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  background: #fff;
  z-index: 1045;
  display: none;
  overflow: auto;

  &:not(.without-padding) {
    padding-right: calc(1rem * 0.5);
    padding-left: calc(1rem * 0.5);
  }

  &.active {
    display: block;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid #f2f2f2;
    box-shadow: 3px 3px 6px #e1e1e1;
    border-radius: 5px;

    li {
      padding: 3px 1rem;
      cursor: pointer;
      transition: background-color .2s linear;

      &:hover {
        background-color: #f2f2f2;
      }

      &.selected {
        background-color: #f2f2f2;
      }
    }
  }
}