.auth-page-wrapper {

  .auth-one-bg {
    background-image: url('~@/assets/images/example-team.jpg');
    background-position: center center;
    opacity: 0.5;

    .bg-overlay {
      background: linear-gradient(rgba(255, 255, 255, 1) 60%, rgba(133, 133, 133, 1));
      opacity: 0.7;
    }
  }

  .auth-one-bg-position {
    height: 100%;
  }

  .auth-page-content {
    .login-title {
      color: #003b4d;
    }

    .btn-success {
      background-color: $color-white;
    }
  }
}