//
// _form-check.scss
//

.form-check.form-check-outline.form-check-success {
  .form-check-input {
    &:checked[type=checkbox] {
      color: $secondary-color;
      border-color: $secondary-color;
    }
  }
}