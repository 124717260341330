$main-red: #D30201;
$woorpla-main-red: #DE0110;
$primary-color: #003b4d;
$secondary-color: #5399a5;

$popup-bg-color: rgba(0, 0, 0, 0.1);
$popup-form-bg: #ffffff;

$menu-text-color: #34353e;

// Collors
$color-white: #FFFFFF;
$color-black: #000000;
$color-light-gray: #878A99;

// Border-radius
$form-border-radius: 0.25rem;

// shadows
$form-popup-shadow: 3px 3px 6px #bfbfbf;

// Input groups
$checkbox-without-label-margin-top: 2.1875rem;
$input-border-color: #CED4DA;
$input-error-border-color: #ED5E5E;

// Checkmark / Cross icons font size
$checked-cross-icons-font-size: 1.2rem;

// Profile page
$profile-label-basis: 190px;
$profile-label-font-weight: 600;
$profile-label-margin-right: 4px;
$profile-label-margin-bottom: 0.5rem;
$profile-foreground-color: #333;

$user-avatar-bg-color: $secondary-color;
$user-avatar-text-color: #fff;
$user-avatar-font-weight: 600;
$user-avatar-font-size: 0.825rem;
$user-lg-avatar-font-size: 2rem;

// Project page
$project-label-basis: 190px;
$project-label-font-weight: 600;
$project-label-margin-right: 4px;
$project-label-margin-bottom: 0.5rem;

$filters-counter-size: 1.2rem;
$filters-counter-font-size: 0.721875rem;

$archived-bg-color: rgb(255, 205, 104);
$archived-color: rgb(33, 37, 41);

$blacklisted-bg-color: #DFDFDF;
$blacklisted-color: inherit;

$warning-modal-font-size: 1rem;

// Disabled background
$disabled-option-bg: #EBEBEB;
$disabled-option-color: #6C757D;
$disabled-text-color: #6C757D;
$disabled-text-font-size: 0.6rem;
$disabled-text-line-height: 0.5rem;

$time-input-placeholder-color: #ababab;
$time-input-border-color: #CED4DA;
$time-input-border-radius: 4px;

// Table component
$table-head-bg-color: var(--vz-table-bg);
$table-bg-color: #FFF;
$scrollbar-bg-color: #f3f3f3;
$scrollbar-thumb-bg-color: #B2B2B2;
$table-border-color: #E9EBEC;

// Table skeleton loading
$table-skeleton-cell-bg-color: #F4F4F4;
$table-skeleton-cell-shine-bg-color: rgba(229, 229, 229, 0.8);
$table-skeleton-animation-duration: 1.0s;
$table-skeleton-cell-border-color: #CCC;

// Timekeeping table component
$timekeeping-table-weekend-background: #E5DFEC;
$table-row-hover-background: #F3F6F9;

$timekeeping-table-today-border-color: red;
$timekeeping-table-weekday-font-weight: 400;
$timekeeping-table-future-color: #B7B7B7;

$updating-timekeeping-input-color: transparent;
$updating-timekeeping-input-bg-color: #F7F7F7CC;
$updating-timekeeping-input-loader-size: 18px;
$updating-timekeeping-input-loader-border-color: #CDCDCD;
$timekeeping-night-shift-input-bg-color: #FFFF00FF;

$schedule-title-font-weight: 600;
$schedule-title-line-height: 1.2;
$schedule-title-color: var(--vz-heading-color);
$schedule-title-font: "Montserrat", sans-serif;

$table-border-width: 1px;

// Performed table
$performed-table-date-font-size: 0.92rem;
$performed-table-cell-padding: 0.625rem 0.75rem;
$performed-table-input-padding: 0.25rem 0.125rem;
$performed-table-textarea-width: 14rem;

// Rotation table
$rotation-table-expand-button-top: 10px;
$rotation-table-expand-button-right: $rotation-table-expand-button-top;
$rotation-table-month-cell-min-width: 45px;
$rotation-table-default-input-width: 90px;