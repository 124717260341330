/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
assets/config/saas/custom.scss
*/

.dropzone-container {
  .dropzone {
    border: 2px dashed $secondary-color;
  }
}

.flatpickr-calendar {
  span.flatpickr-day {
    &.selected,
    &.inRange,
    &:hover {
      color: $color-white;

      &.today {
        color: $color-black;
      }
    }

    &.inRange,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.today.inRange,
    &.prevMonthDay.today.inRange,
    &.nextMonthDay.today.inRange,
    &:hover,
    &.prevMonthDay:hover,
    &.nextMonthDay:hover,
    &:focus,
    &.prevMonthDay:focus,
    &.nextMonthDay:focus {
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }
}