.user-avatar {
  align-items: center;
  background-color: $user-avatar-bg-color;
  border-radius: 50%;
  color: $user-avatar-text-color;
  display: flex;
  font-size: $user-avatar-font-size;
  font-weight: $user-avatar-font-weight;
  justify-content: center;
  text-align: center;
  padding-top: 1px;

  &.avatar-lg {
    font-size: $user-lg-avatar-font-size;
  }
}